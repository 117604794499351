@use '../../app/common/components/input/editor-input/editor.scss';
@use '../../app/common/components/chip/chip.scss';
@use '../../app/common/components/expansionpanel/expansionpanel.scss';
@use '../../app/common/components/button/button.scss';

.k-button-solid-dark:hover,
.k-button-solid-dark.k-hover {
    @apply border-transparent bg-inherit;
}

.k-drawer-item.k-selected {
    @apply bg-card text-card-foreground brightness-90;
}
.k-drawer-item:hover,
.k-drawer-item.k-hover {
    @apply bg-card text-card-foreground brightness-95;
}

.k-drawer-item:focus:hover,
.k-drawer-item:focus.k-hover,
.k-drawer-item.k-focus:hover,
.k-drawer-item.k-focus.k-hover {
    @apply bg-card text-card-foreground brightness-95;
}

// Selected and hover
.k-drawer-item.k-selected:hover,
.k-drawer-item.k-selected.k-hover {
    @apply bg-card text-card-foreground brightness-95;
}

.k-panelbar > .k-item > .k-link.k-selected:hover,
.k-panelbar > .k-item > .k-link.k-selected.k-hover,
.k-panelbar > .k-panelbar-header > .k-link.k-selected:hover,
.k-panelbar > .k-panelbar-header > .k-link.k-selected.k-hover {
    @apply bg-card text-card-foreground;
}

// Selected
.k-panelbar > .k-item > .k-link.k-selected,
.k-panelbar > .k-panelbar-header > .k-link.k-selected {
    @apply bg-card text-card-foreground;
}

.k-content {
    @apply bg-card text-card-foreground;
    border-color: rgba(0, 0, 0, 0.08);
}

.k-panelbar > .k-item > .k-link,
.k-panelbar > .k-panelbar-header > .k-link,
.k-panelbar > .k-item > .k-link:hover,
.k-panelbar > .k-item > .k-link.k-hover,
.k-panelbar > .k-panelbar-header > .k-link:hover,
.k-panelbar > .k-panelbar-header > .k-link.k-hover {
    @apply bg-card text-card-foreground transition-none;
}

/*
* Grid
*/

.k-grid .k-grid-content td,
.k-grid .k-grid-content app-link .text-link,
// when we have columnHeaderTooltip applied
.k-grid .k-grid-header th .k-link span {
    @apply overflow-hidden text-ellipsis whitespace-nowrap;
}

.k-grid {
    .k-grid-table .k-checkbox,
    .k-header .k-checkbox {
        &:checked {
            @apply border-none;
        }
        &:focus {
            @apply shadow-none;
        }
    }

    .k-table-td,
    .k-table-th {
        &:has(.k-checkbox-wrap) {
            span.k-cell-inner.ng-star-inserted {
                @apply hidden;
            }

            @apply text-clip text-center;
        }
    }

    .k-grid-table .k-checkbox:checked {
        @apply bg-primary;
    }

    .k-header .k-checkbox:checked {
        @apply brightness-90;
    }

    .k-table-row.k-selected > .k-table-td {
        @apply bg-primary/20;
    }
}

.k-grid-header,
.k-grouping-header,
.k-grid-add-row,
.k-grid-footer,
.k-grid-content,
.k-pager,
.k-pager-sizes > .k-dropdown,
.k-table,
.k-data-table,
.k-grid,
.k-table-thead,
.k-table-header,
.k-table-group-sticky-header {
    @apply bg-card text-card-foreground;
}

.k-grid .k-table-tbody > .k-table-row:not(.k-detail-row):hover,
.k-grid .k-table-tbody > .k-table-row:not(.k-detail-row).k-hover {
    @apply bg-popover brightness-90;
}

.k-window,
.k-dialog-titlebar {
    @apply rounded-t-md bg-card text-card-foreground;
}

.k-window.k-dialog {
    @apply rounded-md;
}

.k-window-content,
.k-prompt-container {
    padding-block: unset;
    padding-inline: unset;
}

.k-window-content:first-child {
    padding-top: unset;
}

.k-window-title {
    padding-block: unset;
    padding-inline: unset;
}

.k-splitter-flex .k-pane {
    @apply h-screen;
}

.k-menu-group .k-item:focus > .k-link,
.k-menu-group .k-item.k-focus > .k-link,
.k-menu.k-context-menu .k-item:focus > .k-link,
.k-menu.k-context-menu .k-item.k-focus > .k-link,
.k-list .k-list-item:focus,
.k-list .k-list-item.k-focus {
    @apply shadow-none;
}

.k-menu-group .k-item > .k-link:active,
.k-menu-group .k-item > .k-link.k-active,
.k-menu-group .k-item > .k-link.k-selected,
.k-menu.k-context-menu .k-item > .k-link:active,
.k-menu.k-context-menu .k-item > .k-link.k-active,
.k-menu.k-context-menu .k-item > .k-link.k-selected,
.k-list .k-list-item:active,
.k-list .k-list-item.k-active,
.k-list .k-list-item.k-selected {
    @apply bg-popover text-popover-foreground contrast-125;
}

.k-menu-group .k-item > .k-link:hover,
.k-menu-group .k-item > .k-link.k-hover,
.k-menu.k-context-menu .k-item > .k-link:hover,
.k-menu.k-context-menu .k-item > .k-link.k-hover,
.k-list .k-list-item:hover,
.k-list .k-list-item.k-hover {
    @apply bg-popover text-popover-foreground brightness-90;
}

.k-menu-group-md .k-menu-item,
.k-list .k-list-item {
    @apply bg-popover text-popover-foreground;
}

.k-grid-header .k-header,
.k-grouping-row,
.k-grouping-row:hover,
.k-group-cell,
tr.k-group-footer td {
    @apply bg-quaternary text-foreground;
}

/*
* Tabstrip
*/

kendo-tabstrip.k-tabstrip {
    .k-tabstrip-items-wrapper {
        @apply border-background-darker;
    }
    .k-tabstrip-items-wrapper .k-item {
        @apply select-none rounded-t-md border-t-4 border-t-transparent bg-card text-xs text-card-foreground shadow-none;

        &.k-active {
            @apply border-b-0 border-t-4 border-t-primary font-bold;
        }

        &:hover {
            @apply bg-primary text-primary-foreground;
        }

        &.hidden-tab {
            @apply hidden;
        }
    }

    .k-tabstrip-content {
        @apply rounded-b-md border-none bg-card p-0 text-card-foreground outline-none;
    }
}

/*
*   Editable grid
*/

app-editable-grid .k-grid {
    > .k-grid-toolbar {
        @apply justify-end;

        // > button.k-grid-add-command {
        //     @apply bg-primary text-primary-foreground;
        // }
    }

    .k-table-tbody .k-table-row:is(.k-detail-row):hover,
    .k-table-list .k-table-row:is(.k-detail-row):hover,
    .k-table-tbody .k-table-row:is(.k-detail-row).k-hover,
    .k-table-list .k-table-row:is(.k-detail-row).k-hover {
        @apply bg-inherit;
    }

    kendo-grid-list > div > div.k-grid-table-wrap > table > tbody > tr {
        &:hover > td.k-hierarchy-cell kendo-svg-icon.k-svg-icon {
            @apply bg-inherit;
        }

        > td {
            &.k-hierarchy-cell {
                @apply pe-0 ps-0;
                kendo-svg-icon.k-svg-icon {
                    @apply bg-card text-card-foreground;
                }
            }

            > .k-button {
                @apply flex h-8 w-8 items-center justify-center border-none shadow transition-all;
            }
            button.k-grid-edit-command,
            button.k-grid-save-command {
                @apply bg-primary text-primary-foreground hover:bg-primary hover:brightness-110;
            }

            button.k-grid-remove-command,
            button.k-grid-cancel-command {
                @apply bg-danger text-danger-foreground hover:bg-danger hover:brightness-110;
            }
        }
    }
}

/*
*   App controls
*/

.k-multiselect-popup,
.k-combobox-popup {
    .k-list-item.k-selected {
        @apply bg-primary text-primary-foreground hover:brightness-90;
    }
}

.k-datetime-container .k-datetime-wrap {
    div.k-datetime-buttongroup > div.k-button-group > button.k-button {
        @apply hover:brightness-90;

        &.k-active {
            @apply bg-primary text-primary-foreground;
        }
    }

    .k-datetime-selector {
        @apply h-64;
        .k-datetime-calendar-wrap .k-calendar-header button.k-calendar-nav-today,
        .k-datetime-time-wrap .k-timeselector .k-time-header button.k-time-now {
            @apply text-primary;
        }

        .k-datetime-calendar-wrap .k-calendar-view {
            @apply mb-0 mt-0 h-full;
            .k-content {
                @apply bg-white text-black;
            }

            .k-today {
                @apply text-primary;
            }

            .k-selected > span.k-link {
                @apply bg-primary text-primary-foreground;
            }
        }

        .k-datetime-time-wrap .k-timeselector {
            @apply mb-0 mt-0 h-full;
            .k-content {
                @apply bg-white text-black;

                ul.k-reset {
                    transform: translateY(84px) !important;

                    li.k-item {
                        @apply hover:text-primary;
                    }
                }
            }
            .k-time-highlight {
                @apply z-10 bg-primary/10;
            }
        }
    }

    div.k-datetime-footer {
        @apply mt-0;

        button.k-time-accept {
            @apply border-primary bg-primary text-primary-foreground hover:brightness-90;
        }

        button.k-time-cancel {
            @apply bg-danger text-danger-foreground hover:brightness-90;
        }
    }
}

.k-calendar-container {
    .k-calendar-view {
        .k-content {
            @apply bg-white text-black;
        }

        .k-calendar-nav-today,
        .k-today {
            @apply text-primary;
        }

        .k-selected > span.k-link {
            @apply bg-primary text-primary-foreground;
        }
    }
}

.k-panelbar {
    @apply border-none bg-card;
}

/**
* Sidebar
*/

#sidebar.k-panelbar > .k-item > .k-link,
#sidebar.k-panelbar > .k-panelbar-header > .k-link {
    @apply relative flex select-none flex-row flex-nowrap content-center items-center rounded-md bg-card p-0 text-card-foreground no-underline shadow-none transition-none;
}

#sidebar.k-panelbar > .k-panelbar-header {
    @apply py-0.5;
}

#sidebar.k-panelbar .k-group > .k-item > .k-link,
#sidebar.k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link {
    @apply rounded-md p-0;
}

#sidebar.k-panelbar > .k-panelbar-header.hidden-carret .k-link {
    @apply justify-center;
}

// Hover
#sidebar.k-panelbar .k-group .k-item > .k-link:hover,
#sidebar.k-panelbar .k-group .k-item > .k-link.k-hover,
#sidebar.k-panelbar .k-group .k-panelbar-item > .k-link:hover,
#sidebar.k-panelbar .k-group .k-panelbar-item > .k-link.k-hover,
#sidebar.k-panelbar .k-panelbar-group .k-item > .k-link:hover,
#sidebar.k-panelbar .k-panelbar-group .k-item > .k-link.k-hover,
#sidebar.k-panelbar .k-panelbar-group .k-panelbar-item > .k-link:hover,
#sidebar.k-panelbar .k-panelbar-group .k-panelbar-item > .k-link.k-hover,
#sidebar.k-panelbar > .k-panelbar-header > .k-link:hover {
    @apply bg-primary/10 text-primary-foreground;
}

// Selected
#sidebar.k-panelbar > .k-panelbar-header > .k-link.k-selected,
#sidebar.k-panelbar .k-group .k-panelbar-item > .k-link.k-selected,
#sidebar.k-panelbar .k-panelbar-group .k-panelbar-item > .k-link.k-selected {
    @apply bg-primary/20 text-primary-foreground;
}

// Selected and hover
#sidebar.k-panelbar > .k-panelbar-header > .k-link.k-selected:hover,
#sidebar.k-panelbar .k-group > .k-panelbar-item > .k-link.k-selected:hover,
#sidebar.k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link.k-selected:hover {
    @apply bg-primary/30 text-primary-foreground;
}

// Focus
#sidebar.k-panelbar .k-group > .k-item > .k-link:focus,
#sidebar.k-panelbar .k-group > .k-item > .k-link.k-focus,
#sidebar.k-panelbar .k-group > .k-panelbar-item > .k-link:focus,
#sidebar.k-panelbar .k-group > .k-panelbar-item > .k-link.k-focus,
#sidebar.k-panelbar .k-panelbar-group > .k-item > .k-link:focus,
#sidebar.k-panelbar .k-panelbar-group > .k-item > .k-link.k-focus,
#sidebar.k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link:focus,
#sidebar.k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link.k-focus {
    @apply shadow-none;
}

// Icon
#sidebar.k-panelbar .k-item > .k-link .k-icon,
#sidebar.k-panelbar .k-item > .k-link .k-panelbar-item-icon,
#sidebar.k-panelbar > .k-panelbar-header .k-link .k-icon,
#sidebar.k-panelbar > .k-panelbar-header .k-link .k-panelbar-item-icon {
    @apply absolute right-2 cursor-pointer text-card-foreground transition-all;
}

.hidden-carret .k-link .k-icon-wrapper-host {
    @apply hidden;
}

.k-grid-toolbar {
    @apply rounded-t-lg bg-tertiary;
}

.k-pager-wrap,
.k-grid-pager {
    @apply rounded-b-lg;
}

.k-svg-i-sort-asc-small,
.k-svg-i-sort-desc-small {
    @apply text-primary-foreground;
}

/**
* Grid Toolbar
*/

.k-popup {
    @apply rounded-none border-popover bg-popover text-popover-foreground;

    .k-checkbox {
        @apply bg-popover text-popover-foreground;

        &:checked {
            @apply border-primary bg-primary text-primary-foreground;
        }
    }

    .k-column-list-item:hover,
    .k-column-list-item.k-hover {
        @apply bg-popover text-popover-foreground brightness-90;
    }
}

.k-toolbar .k-input {
    // override width when placing app-control input in grid toolbar
    width: 100%;
}
