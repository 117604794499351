.k-expander {
    @apply border-foreground/10 bg-card text-card-foreground;
}

.k-expander-header {
    @apply text-card-foreground;
}

// custom class to apply to buttons in the expansion panel. They show on hover or when the panel is expanded
.edit-buttons {
    @apply ms-2 flex gap-1 text-muted/70 transition-all;

    .k-expander.k-expanded & {
        @apply hover:delay-200;
    }

    .k-expander:not(.k-expanded) & {
        @apply opacity-0;
    }

    .k-expander:not(.k-expanded):hover & {
        @apply opacity-100 delay-200;
    }

    a {
        @apply text-muted/70;
    }
}
