.k-editor {
    @apply rounded border bg-input text-input-foreground;

    .k-button-solid-base {
        @apply bg-input text-input-foreground;
    }

    .k-button-solid-base.k-selected {
        @apply border-accent bg-accent;
    }

    .k-picker-solid {
        @apply bg-input text-input-foreground;
    }
}

.k-editor-toolbar {
    @apply rounded-t bg-card text-input-foreground;
}
